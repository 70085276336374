import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import AdminApi from "../api/admin";
import Toast from "./Toast";
const { Content } = Layout;

type Customer = {
  id: string;
  companyId: string;
  createdAt: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

const CustomerReferrals: React.FC<{ companyId: string }> = ({ companyId }) => {
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    // Normally, you would fetch the customers from an API
    setOptions(
      customers
        .map((customer, index) => ({
          value: `${customer.firstName} ${customer.lastName}: ${customer.phone}, ${customer.email}`,
          label: `${customer.firstName} ${customer.lastName}: ${customer.phone}, ${customer.email}`,
        }))
        .filter((customerSearchObject) => {
          return customerSearchObject.label
            .toLowerCase()
            .includes(searchText.trim().toLowerCase());
        })
    );
    //  we only want to run this effect when the customers change, not the text, that is handled by a different useEffect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    async function fetchCustomers() {
      let customers = (await AdminApi.searchCustomers(companyId, searchText))
        .customers;
      setCustomers(customers || []);
    }
    fetchCustomers();
  }, [companyId, searchText]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [performingAction, setPerformingAction] = useState(false);

  function resetInputs() {
    setFirstName("");
    setLastName("");
    setPhoneNumber("");
    setEmail("");
    setNotes("");
    setSelectedCustomer(null);
  }

  async function submitReferral() {
    if (!selectedCustomer) {
      return;
    }
    setSubmitting(true);
    try {
      let response = await AdminApi.submitReferral(
        companyId,
        selectedCustomer.id,
        {
          firstName,
          lastName,
          email,
          phone: phoneNumber,
          notes,
        },
        "STANDARD"
      );
      if (response.success) {
        // do something
        resetInputs();
      }
    } catch (error) {
      // handle error
    } finally {
      setSubmitting(false);
    }
  }

  async function submitSelfReferral() {
    if (!selectedCustomer) {
      return;
    }
    setSubmitting(true);
    try {
      let response = await AdminApi.submitReferral(
        companyId,
        selectedCustomer.id,
        {
          firstName: selectedCustomer.firstName,
          lastName: selectedCustomer.lastName,
          email: selectedCustomer.email,
          phone: selectedCustomer.phone,
          notes,
        },
        "SELF"
      );
      if (response.success) {
        // do something
        resetInputs();
      }
    } catch (error) {
      // handle error
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Content
        style={{
          padding: "20px",
          display: "flex",
        }}
      >
        <Row style={{ width: "100%", justifyContent: "space-around" }}>
          <Col span={6}>
            <Typography style={{ fontSize: "20px", paddingBottom: "30px" }}>
              Search for a customer
            </Typography>
            <AutoComplete
              options={options}
              style={{ width: "100%" }}
              placeholder="Search for a customer"
              onSearch={setSearchText}
              onSelect={(value: string) => {
                let selectedCustomer = customers.find((customer) => {
                  return (
                    `${customer.firstName} ${customer.lastName}: ${customer.phone}, ${customer.email}` ===
                    value
                  );
                });
                setSelectedCustomer(selectedCustomer || null);
              }}
            />
          </Col>
          <Col span={6}>
            <Typography style={{ fontSize: "20px", paddingBottom: "30px" }}>
              {selectedCustomer
                ? "Actions"
                : "Select a customer to perform actions"}
            </Typography>
            {/* <Button
              style={{
                width: "100%",
                marginTop: "10px",
              }}
              disabled={!selectedCustomer}
            >
              Delete Customer
            </Button> */}
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              disabled={!selectedCustomer || performingAction}
              onClick={async () => {
                if (!selectedCustomer) {
                  return;
                }
                setPerformingAction(true);
                const response = await AdminApi.optOutCustomer(
                  selectedCustomer.companyId,
                  selectedCustomer.phone
                );
                if (response.success) {
                  Toast("success", "Customer phone number has been opted out");
                } else {
                  Toast("error", "Error opting out customer phone number");
                }
                setPerformingAction(false);
              }}
            >
              Opt Out Customer Phone Number
            </Button>
            {/* <Button
              style={{ width: "100%", marginTop: "10px" }}
              disabled={!selectedCustomer}
            >
              Opt In Customer
            </Button> */}
          </Col>
          <Col span={6}>
            {selectedCustomer ? (
              <>
                <Typography style={{ fontSize: "20px", paddingBottom: "30px" }}>
                  Submit a Referral
                </Typography>
                <Form layout="vertical">
                  <Form.Item
                    label={<Typography>Referral First Name</Typography>}
                  >
                    <Input
                      placeholder="Enter their first name"
                      value={firstName}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Typography>Referral Last Name</Typography>}
                  >
                    <Input
                      placeholder="Enter their last name"
                      value={lastName}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label={<Typography>Referral Phone</Typography>}>
                    <Input
                      placeholder="Enter their phone number"
                      value={phoneNumber}
                      onChange={(event) => {
                        // validate input
                        if (event.target.value.length > 10) {
                          return;
                        }
                        if (isNaN(Number(event.target.value))) {
                          return;
                        }
                        setPhoneNumber(event.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={<Typography>Referral Email</Typography>}>
                    <Input
                      placeholder="Enter their email"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label={<Typography>Notes</Typography>}>
                    <Input.TextArea
                      placeholder="Enter notes"
                      value={notes}
                      onChange={(event) => setNotes(event.target.value)}
                    />
                  </Form.Item>
                  <Row style={{ width: "100%" }}>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        style={{ width: "120px" }}
                        disabled={
                          submitting || !firstName || (!phoneNumber && !email)
                        }
                        onClick={submitReferral}
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        style={{ width: "120px" }}
                        disabled={
                          submitting ||
                          firstName !== "" ||
                          lastName !== "" ||
                          phoneNumber !== "" ||
                          email !== ""
                        }
                        onClick={submitSelfReferral}
                      >
                        Self Referral
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            ) : (
              <Typography>Select a customer to submit a referral</Typography>
            )}
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default CustomerReferrals;
