import { CheckOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Card, Col, Input, Row, Space, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../api/ApiContext";

const { Search } = Input;

// class="ant-table-row ant-table-row-level-0"

const CompaniesStyle = styled.div({
  "&.companiesStyleRoot": {
    ".ant-table-row": {
      cursor: "pointer",
    },
  },
});

function formatTimestamp(timestamp: number): string {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function padZero(num: number): string {
  return num.toString().padStart(2, "0");
}

const Companies: React.FC = () => {
  const navigate = useNavigate();

  const [companiesList, setCompaniesList] = useState<any>([]);
  const [tableData, setTableDataWithKeys] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { fetchCompanies } = useContext(ApiContext);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const result = await fetchCompanies();
      if (result) {
        setCompaniesList(result);
        setTableData(result);
      }
      setIsLoading(false);
    };
    fetch();
  }, [fetchCompanies]);

  const handleSearch = (value: string) => {
    if (!value || value === "") return setTableData(companiesList);

    const filteredData = (companiesList || []).filter(
      (item: any) =>
        item.name?.toLowerCase().includes(value.toLowerCase()) ||
        item.domain?.toLowerCase().includes(value.toLowerCase()) ||
        item.id?.toLowerCase().includes(value.toLowerCase())
    );
    setTableData(filteredData);
  };

  const setTableData = (data: any[]) => {
    setTableDataWithKeys(
      data.map((company) => ({
        ...company,
        key: company.id,
      }))
    );
  };

  return (
    <CompaniesStyle className="companiesStyleRoot">
      <Card style={{ margin: 20 }}>
        <Space
          direction="vertical"
          style={{ width: "100%" }}
        >
          <Row>
            <Col span={24}>
              <Search
                placeholder="Search by name or website"
                onSearch={handleSearch}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    sorter: (a, b) => a.name.localeCompare(b.name),
                    sortDirections: ["ascend", "descend"],
                  },
                  { title: "Website", dataIndex: "domain", key: "domain" },
                  {
                    title: "Paid",
                    dataIndex: "activeSubscription",
                    key: "activeSubscription",
                    render: (activeSubscription: boolean) => (
                      <div style={{ textAlign: "center" }}>
                        {activeSubscription ? (
                          <CheckOutlined style={{ color: "green" }} />
                        ) : (
                          ""
                        )}
                      </div>
                    ),
                  },
                  {
                    title: "Created At",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
                    sortDirections: ["ascend", "descend"],
                    render: (text: any) => formatTimestamp(text),
                  },
                ]}
                dataSource={tableData}
                pagination={false}
                className="ant-border-space"
                loading={isLoading}
                onRow={(company) => ({
                  onClick: () => navigate("/company/" + company.id),
                })}
              />
            </Col>
          </Row>
        </Space>
      </Card>
    </CompaniesStyle>
  );
};

export default Companies;
