import { Col, Divider, Modal, Row, Space, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import AdminApi from "../../../api/admin";
import AssignNewPhoneNumber from "./AssignNewPhoneNumber";
import PhoneNumberTable from "./PhoneNumberTable";

interface PhoneNumber {
  id: string;
  phoneNumber: string;
  provider: string;
  createdAt: number;
  deletedAt?: number;
}

interface AssignPhoneNumberModalProps {
  visible: boolean;
  onClose: () => void;
  companyId: string;
  companyName: string;
}

const AssignPhoneNumberModal: React.FC<AssignPhoneNumberModalProps> = ({
  visible,
  onClose,
  companyId,
  companyName,
}) => {
  const [allAssignedPhoneNumbers, setAllAssignedPhoneNumbers] = useState<
    PhoneNumber[]
  >([]);
  const [message, contextHolder] = notification.useNotification();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [shouldFetchPhoneNumbers, setShouldFetchPhoneNumbers] =
    useState<boolean>(true);

  const handleDeletePhoneNumber = async (id: string) => {
    setSpinning(true);
    const response = await AdminApi.deleteAssignedPhoneNumber(id);
    setShouldFetchPhoneNumbers(true);
    setSpinning(false);
    if (response.success) {
      message.success({
        message: "Success",
      });
    } else {
      message.error({
        message: "Error",
        description: response.message,
      });
    }
  };

  const handleAddPhoneNumber = async (
    phoneNumber: string,
    provider: string,
    accountSid?: string,
    authToken?: string,
    messagingServiceId?: string,
    applicationId?: string
  ) => {
    setSpinning(true);
    const response = await AdminApi.assignPhoneNumber(
      companyId,
      phoneNumber,
      provider,
      accountSid,
      authToken,
      messagingServiceId,
      applicationId
    );
    setShouldFetchPhoneNumbers(true);
    setSpinning(false);
    if (response.success) {
      message.success({
        message: "Success",
      });
    } else {
      message.error({
        message: "Error",
        description: response.message,
      });
    }
  };

  useEffect(() => {
    const fetchPhoneNumbers = async () => {
      setSpinning(true);

      const providers: { [index: number]: string } = {
        0: "Twilio",
        1: "Plivo",
        2: "Bandwidth",
      };

      const makeKeyedPhoneNumbers = (phoneNumbers: PhoneNumber[]) => {
        return phoneNumbers.map((p, i) => ({
          ...p,
          provider: providers[p.provider as any],
          key: i,
        }));
      };

      const data = await AdminApi.listAssignedPhoneNumbers(companyId);
      if (data.success) {
        const keyedPhoneNumbers = makeKeyedPhoneNumbers(data.message);
        setAllAssignedPhoneNumbers(keyedPhoneNumbers);
      }
      setShouldFetchPhoneNumbers(false);
      setSpinning(false);
    };

    if (companyId && shouldFetchPhoneNumbers) {
      fetchPhoneNumbers();
    }
  }, [companyId, shouldFetchPhoneNumbers]);

  return (
    <>
      {contextHolder}
      <Modal
        title={`Assign Phone Number for ${companyName}`}
        visible={visible}
        onCancel={onClose}
        onOk={onClose}
        width={600}
      >
        <Spin
          spinning={spinning}
          fullscreen
        />
        <Row justify="space-between">
          <Col
            span="24"
            style={{ display: companyId ? "" : "none", marginTop: "1.5rem" }}
          >
            <Row>
              <Col span="24">
                <AssignNewPhoneNumber onSubmit={handleAddPhoneNumber} />
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ display: companyId ? "" : "none" }}>
          <Divider />
          <Space
            direction="vertical"
            style={{ width: "100%" }}
          >
            <Row>
              <Col span="24">
                <h4>Existing Numbers</h4>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <PhoneNumberTable
                  phoneNumbers={allAssignedPhoneNumbers}
                  onDeletePhoneNumber={handleDeletePhoneNumber}
                />
              </Col>
            </Row>
          </Space>
          <Divider />
        </div>
      </Modal>
    </>
  );
};

export default AssignPhoneNumberModal;
