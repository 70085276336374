import React, { ReactNode, createContext, useEffect, useState } from "react";
import { REFERPRO_ADMIN_TOKEN_NAME, REFERPRO_USER_ID_NAME } from "../constants";

type AuthContextType = {
  userId: string | null;
  isLoggedIn: boolean;
  // eslint-disable-next-line no-unused-vars
  login: (apiToken: string) => void;
  logout: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  userId: null,
  isLoggedIn: false,
  // eslint-disable-next-line no-unused-vars
  login: (apiToken: string) => {},
  logout: () => {},
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [apiToken, setApiToken] = useState<string | null>(
    localStorage.getItem(REFERPRO_ADMIN_TOKEN_NAME)
  );
  const [userId, setUserId] = useState<string | null>(
    localStorage.getItem(REFERPRO_USER_ID_NAME)
  );

  const isLoggedIn = !!apiToken;

  useEffect(() => {
    const apiToken = localStorage.getItem(REFERPRO_ADMIN_TOKEN_NAME);
    const userId = localStorage.getItem(REFERPRO_USER_ID_NAME);

    if (apiToken) {
      setApiToken(apiToken);
    }

    if (userId) {
      setUserId(userId);
    }
  }, []);

  const login = (apiToken: string) => {
    setApiToken(apiToken);
    localStorage.setItem(REFERPRO_ADMIN_TOKEN_NAME, apiToken);
  };

  const logout = () => {
    setApiToken(null);
    localStorage.removeItem(REFERPRO_ADMIN_TOKEN_NAME);
  };

  const authContextValue: AuthContextType = {
    userId,
    isLoggedIn,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
