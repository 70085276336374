import { LogoutOutlined, TeamOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Layout, Menu, MenuProps } from "antd";
import React, { useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { REFERPRO_ADMIN_TOKEN_NAME } from "../constants";
import Companies from "../pages/Companies/Companies";
import Company from "../pages/Company/Company";
import Organizations from "../pages/Organizations/Organizations";
import Organization from "../pages/Organization/Organization";
import ErrorBoundary from "./ErrorBoundary";

const { Content, Header, Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as MenuItem;
}

const Styled = styled.div({
  "&.signedInLayoutRoot": {},
});

const SignedInLayout: React.FC<{ onSignOut: () => void }> = ({ onSignOut }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const defaultSelectedKey = (() => {
    const firstPathSegment = location.pathname.split("/")[1];
    switch (firstPathSegment) {
      case "organizations":
        return "1";
      case "organization":
        return "1";
      case "companies":
        return "2";
      case "company":
        return "2";
      default:
        return "1"; // Default to "1" for other routes
    }
  })();

  const signOut = () => {
    localStorage.removeItem(REFERPRO_ADMIN_TOKEN_NAME);
    window.location.reload();
    onSignOut();
  };

  const items: MenuItem[] = [
    getItem("Organizations", "1", <TeamOutlined />, undefined, () =>
      navigate("/organizations")
    ),
    getItem("Companies", "2", <TeamOutlined />, undefined, () =>
      navigate("/companies")
    ),
    getItem("Sign out", "3", <LogoutOutlined />, undefined, signOut),
  ];

  return (
    <Styled className="signedInLayoutRoot">
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{ textAlign: "center" }}
        >
          <Menu
            theme="dark"
            defaultSelectedKeys={[defaultSelectedKey]}
            mode="inline"
            items={items}
          />
        </Sider>
        <Content>
          <ErrorBoundary>
            <Routes>
              <Route
                path="/organizations"
                element={<Organizations />}
              />
              <Route
                path="/organization/:organizationId"
                element={<Organization />}
              />
              <Route
                path="/companies"
                element={<Companies />}
              />
              <Route
                path="/company/:companyId"
                element={<Company />}
              />
              <Route
                path="*"
                element={<Navigate to="/organizations" />}
              />
            </Routes>
          </ErrorBoundary>
        </Content>
      </Layout>
    </Styled>
  );
};

export default SignedInLayout;
