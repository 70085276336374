import { Col, Result, Row } from "antd";
import React from "react";

export default class ErrorBoundary extends React.Component<
  { children: any },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Row
          justify="center"
          style={{ width: "100%" }}
        >
          <Col>
            <Result
              status="500"
              title="500"
              subTitle="Sorry, something went wrong."
            />
          </Col>
        </Row>
      );
    }

    return this.props.children;
  }
}
