import { SendOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Input,
  Layout,
  List,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useRef, useState } from "react";

import AdminApi from "../api/admin";
import { formatPhoneNumber } from "../utils";

const { Header, Content, Footer } = Layout;
const { Text } = Typography;
const { TextArea } = Input;

export function formatDateTime(
  timestamp: number,
  includeTime: boolean = true
): string {
  const dateTime = new Date(timestamp);
  if (dateTime.getTime() > 0) {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      ...(includeTime && {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      }),
    };
    return dateTime.toLocaleString("en-US", options);
  }

  return "";
}

interface Message {
  id: string;
  phone: string;
  text: string;
  sentAt: number;
}

interface Conversation {
  conversation: {
    id: string;
    companyId: string;
    customerId: string;
    createdAt: number;
    customerPhoneNumber: string;
  };
  messages: Message[];
}

const MessageInbox: React.FC<{ companyId: string }> = ({ companyId }) => {
  const [conversationInfo, setConversationInfo] = useState<Conversation[]>([]);
  const [cachedMessages, setCachedMessages] = useState<{
    [key: string]: Message[];
  }>({});
  const cachedMessagesRef = useRef<{ [key: string]: Message[] }>({});
  const [selectedConversationId, setSelectedConversationId] = useState<
    string | null
  >(null);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(false);
  const [isLoadingConversation, setIsLoadingConversation] = useState<{
    [key: string]: boolean;
  }>({});
  const isLoadingConversationRef = useRef<{ [key: string]: boolean }>({});
  const [isSending, setIsSending] = useState<boolean>(false);
  const [message, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState<string>("");
  const searchTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleSelectConversation = async (conversation: Conversation) => {
    setSelectedConversationId(conversation.conversation.id);
    if (!cachedMessagesRef.current[conversation.conversation.id]) {
      setIsLoadingConversation({
        ...isLoadingConversationRef.current,
        [conversation.conversation.id]: true,
      });
      isLoadingConversationRef.current[conversation.conversation.id] = true;
      // fetch the conversation
      const data = await AdminApi.getConversation(conversation.conversation.id);
      if (data.success) {
        setCachedMessages({
          ...cachedMessagesRef.current,
          [conversation.conversation.id]: data.messages,
        });
        cachedMessagesRef.current[conversation.conversation.id] = data.messages;
      }
      setIsLoadingConversation({
        ...isLoadingConversationRef.current,
        [conversation.conversation.id]: false,
      });
      isLoadingConversationRef.current[conversation.conversation.id] = false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingMessages(true);
      const data = await AdminApi.listConversations(companyId, search);
      if (data.success) {
        // Because the conversation messages only have a to, not a from then we need to do some magic
        const fetchedConversations: Conversation[] = [];
        data.conversations.map((conversation: Conversation) => {
          // determine the 2 phone numbers from the conversation
          const phoneNumbers: { [key: string]: number } = {};
          conversation.messages.forEach((message) => {
            phoneNumbers[message.phone] = 1;
          });
          // Swap them, because why not?!
          const firstNumber = Object.keys(phoneNumbers)[0];
          const secondNumber = Object.keys(phoneNumbers)[1];
          for (let i = 0; i < conversation.messages.length; i++) {
            if (conversation.messages[i].phone === firstNumber) {
              conversation.messages[i].phone = secondNumber;
            } else {
              conversation.messages[i].phone = firstNumber;
            }
          }
          fetchedConversations.push(conversation);
        });
        setConversationInfo(fetchedConversations);
      }
      setIsLoadingMessages(false);
    };
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(fetchData, 500);
  }, [companyId, search]);

  const handleSendMessage = async () => {
    if (selectedConversationId && newMessage.trim() !== "") {
      setIsSending(true);
      await AdminApi.sendConversationMessage(
        selectedConversationId,
        newMessage
      );
      setNewMessage("");
      setIsSending(false);
      message.success({
        message: "Success",
        description: "Success sending message!",
      });
    }
  };

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedConversationId && messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [selectedConversationId, cachedMessages]);

  return (
    <>
      {contextHolder}
      <Content style={{ padding: "20px", display: "flex" }}>
        <List
          style={{ width: "30%", marginRight: "20px" }}
          header={
            <div>
              Conversations
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search customer phone number"
              />
            </div>
          }
          bordered
          dataSource={conversationInfo}
          loading={isLoadingMessages}
          renderItem={(conversation) => (
            <List.Item
              onClick={() => handleSelectConversation(conversation)}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedConversationId === conversation.conversation.id
                    ? "#f5f5f5"
                    : "white",
              }}
            >
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={formatPhoneNumber(
                  conversation.conversation.customerPhoneNumber
                )}
                description={
                  (cachedMessages[conversation.conversation.id] ?? []).length >
                  0 ? (
                    `${cachedMessages[conversation.conversation.id][
                      cachedMessages[conversation.conversation.id].length - 1
                    ].text.slice(0, 30)} ...`
                  ) : (
                    <Text
                      type="secondary"
                      italic
                    >
                      Select to load messages
                    </Text>
                  )
                }
              />
            </List.Item>
          )}
        />
        {selectedConversationId && (
          <div style={{ flex: 1 }}>
            <div
              ref={messagesContainerRef}
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                marginBottom: "20px",
              }}
            >
              <List
                dataSource={cachedMessages[selectedConversationId] ?? []}
                loading={isLoadingConversation[selectedConversationId]}
                renderItem={(message) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar icon={<UserOutlined />} />}
                      title={message.phone}
                      description={
                        <div>
                          <Text>{message.text}</Text>
                          <br />
                          <Text
                            type="secondary"
                            style={{ fontSize: "12px" }}
                          >
                            {formatDateTime(message.sentAt)}
                          </Text>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
            <TextArea
              rows={4}
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <Button
              loading={isSending}
              type="primary"
              icon={<SendOutlined />}
              onClick={handleSendMessage}
              style={{ marginTop: "10px" }}
            >
              Send
            </Button>
          </div>
        )}
      </Content>
    </>
  );
};

export default MessageInbox;
