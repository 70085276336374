import React, { ReactNode, createContext, useState } from "react";
import Admin from "./admin";

export interface CompanyData {
  id: string;
  name: string;
  domain: string;
}

export interface OrganizationData {
  id: string;
  name: string;
  createdAt: string;
}

interface ApiContextInterface {
  companyData: CompanyData[] | null;
  organizationData: OrganizationData[] | null;
  fetchCompanies: () => Promise<CompanyData[] | null>;
  fetchOrganizations: () => Promise<OrganizationData[] | null>;
}

const ApiContext = createContext<ApiContextInterface>({
  companyData: null,
  organizationData: null,
  fetchCompanies: async () => null,
  fetchOrganizations: async () => null,
});

const ApiContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [companyData, setCompanyData] = useState<CompanyData[] | null>(null);
  const [organizationData, setOrganizationData] = useState<
    OrganizationData[] | null
  >(null);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);

  const fetchCompanies = async () => {
    if (companyData) {
      return companyData;
    }

    if (isCompanyLoading) {
      // Return a promise that resolves when data is available
      return new Promise<CompanyData | null>((resolve) => {
        const intervalId = setInterval(() => {
          if (companyData) {
            clearInterval(intervalId);
            resolve(companyData);
          }
        }, 100);
      });
    }

    setIsCompanyLoading(true);

    try {
      const data = await Admin.listCompanies();
      setCompanyData(data);
      setIsCompanyLoading(false);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsCompanyLoading(false);
      return null;
    }
  };

  const fetchOrganizations = async () => {
    if (organizationData) {
      return organizationData;
    }

    if (isOrganizationLoading) {
      // Return a promise that resolves when data is available
      return new Promise<OrganizationData[] | null>((resolve) => {
        const intervalId = setInterval(() => {
          if (organizationData) {
            clearInterval(intervalId);
            resolve(organizationData);
          }
        }, 100);
      });
    }

    setIsOrganizationLoading(true);

    try {
      const data = await Admin.listOrganizations();
      setOrganizationData(data);
      setIsOrganizationLoading(false);
      return data;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsOrganizationLoading(false);
      return null;
    }
  };

  return (
    <ApiContext.Provider
      value={{
        companyData,
        organizationData,
        fetchCompanies,
        fetchOrganizations,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiContextProvider };
