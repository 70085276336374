import { Layout } from "antd";
import React, { useContext } from "react";
import "./App.css";
import SignedInLayout from "./components/SignedInLayout";
import SignedOutLayout from "./components/SignedOutLayout";
import { AuthContext } from "./providers/AuthProvider";

const { Footer } = Layout;

const App: React.FC = () => {
  const { logout, login, isLoggedIn } = useContext(AuthContext);

  return (
    <div className="App">
      <Layout style={{ minHeight: "100vh" }}>
        {isLoggedIn && <SignedInLayout onSignOut={() => logout()} />}
        {!isLoggedIn && (
          <SignedOutLayout onSignIn={(apiToken: string) => login(apiToken)} />
        )}
        <Footer style={{ backgroundColor: "rgb(7,33,62)" }}></Footer>
      </Layout>
    </div>
  );
};

export default App;
