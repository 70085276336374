// Import React and necessary components from antd
import { CopyOutlined } from "@ant-design/icons";

import { Button, message } from "antd";
import React from "react";

// TypeScript interface for props (if you have any to pass to the component)
interface CopyToClipboardProps {
  textToCopy: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ textToCopy }) => {
  // Function to handle the copy action
  const copyTextToClipboard = async (text: string) => {
    try {
      // Use the Clipboard API to copy text
      await navigator.clipboard.writeText(text);
      // Show a success message
      message.success("Text copied to clipboard!");
    } catch (err) {
      // Show an error message if the copy fails
      message.error("Failed to copy text!");
    }
  };

  return (
    <div>
      {/* Ant Design Button to trigger the copy action */}
      <Button
        type="text"
        onClick={() => copyTextToClipboard(textToCopy)}
      >
        {textToCopy} <CopyOutlined />
      </Button>
    </div>
  );
};

export default CopyToClipboard;
