import { MessagesSent } from "./types";

export function formatChartData(messagesSent: MessagesSent, days: number) {
  const today = new Date();

  const formattedData: MessagesSent = [];

  const messagesSentHash = messagesSent.reduce(
    (acc: { [key: string]: number }, curr) => {
      const date = new Date(curr.day);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");

      acc[`${month}/${day}`] = curr.totalMessages;
      return acc;
    },
    {}
  );

  for (let i = 0; i < days; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const formattedDay = `${month}/${day}`;
    const messagesSent = messagesSentHash[formattedDay] || 0;
    formattedData.push({ totalMessages: messagesSent, day: `${month}/${day}` });
  }

  return formattedData.reverse();
}
