import React from "react";

const formatPhoneNumber = (phoneNumber: string) => {
  if (typeof phoneNumber !== "string") {
    return "N/A";
  }
  let areaCode = "";
  let firstThree = "";
  let lastFour = "";
  // get areaCode
  if (phoneNumber.length >= 3) {
    areaCode = `(${phoneNumber.slice(0, 3)}) `;
  } else {
    areaCode = `(${phoneNumber}`;
  }

  // get firstThree;
  if (phoneNumber.length >= 6) {
    firstThree = phoneNumber.slice(3, 6) + "-";
  } else if (phoneNumber.length > 3 && phoneNumber.length < 6) {
    firstThree = phoneNumber.slice(3, phoneNumber.length);
  }

  // get lastFour
  if (phoneNumber.length > 6) {
    lastFour = phoneNumber.slice(6, phoneNumber.length);
  }

  return `${areaCode}${firstThree}${lastFour}`;
};

const PhoneNumber: React.FC<{ value: string }> = ({ value }) => {
  return <>{formatPhoneNumber(value)}</>;
};

export default PhoneNumber;
