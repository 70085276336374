import { Button, Col, Form, Input, Radio, Row, Select, Space } from "antd";
import React, { useState } from "react";
const { Option } = Select;

interface Props {
  onSubmit: (
    phoneNumber: string,
    provider: string,
    accountSid?: string,
    token?: string,
    messagingServiceId?: string,
    applicationId?: string
  ) => void;
}

const AssignNewPhoneNumber: React.FC<Props> = ({ onSubmit }) => {
  const defaultState = {
    phoneNumber: "",
    phoneNumberProvider: "twilio",
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    defaultState.phoneNumber
  );
  const [phoneNumberProvider, setPhoneNumberProvider] = useState<string>(
    defaultState.phoneNumberProvider
  );
  const [isSubAccount, setIsSubAccount] = useState<boolean>(false);
  const [accountSid, setAccountSid] = useState<string>();
  const [authToken, setAuthToken] = useState<string>();
  const [applicationId, setApplicationId] = useState<string>();
  const [messagingServiceId, setMessagingServiceId] = useState<string>();

  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(
      phoneNumber,
      phoneNumberProvider,
      accountSid,
      authToken,
      messagingServiceId,
      applicationId
    );
    resetForm();
    setIsLoading(false);
  };

  const resetForm = () => {
    setPhoneNumber(defaultState.phoneNumber);
    setPhoneNumberProvider(defaultState.phoneNumberProvider);
  };

  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
    >
      <Row>
        <Col span="24">
          <Form>
            <Form.Item>
              <Input
                placeholder="Phone number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </Form.Item>
            <Form.Item>
              <Select
                showSearch
                placeholder={"twilio"}
                optionFilterProp="children"
                onChange={(id) => setPhoneNumberProvider(id)}
                value={phoneNumberProvider}
              >
                <Option
                  key={1}
                  value={"twilio"}
                >
                  Twilio
                </Option>
                <Option
                  key={2}
                  value={"bandwidth"}
                >
                  Bandwidth
                </Option>
              </Select>
            </Form.Item>
            {phoneNumberProvider === "twilio" ? (
              <>
                <Form.Item label="Has Own Subaccount?">
                  <Radio.Group
                    onChange={(e) => setIsSubAccount(e.target.value)}
                    defaultValue={isSubAccount}
                  >
                    <Radio.Button value={true}>Yes</Radio.Button>
                    <Radio.Button value={false}>No</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {isSubAccount && (
                  <>
                    <Form.Item label="Account Sid">
                      <Input
                        placeholder="Account ID"
                        value={accountSid}
                        onChange={(e) => setAccountSid(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item label="Auth Token">
                      <Input
                        placeholder="Auth Token"
                        value={authToken}
                        onChange={(e) => setAuthToken(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item label="Messaging Service ID">
                      <Input
                        placeholder="Messaging Service ID"
                        value={messagingServiceId}
                        onChange={(e) => setMessagingServiceId(e.target.value)}
                      />
                    </Form.Item>
                  </>
                )}
              </>
            ) : null}
            {phoneNumberProvider === "bandwidth" ? (
              <>
                <Form.Item label="Application ID">
                  <Input
                    placeholder="Application ID"
                    value={applicationId}
                    onChange={(e) => setApplicationId(e.target.value)}
                  />
                </Form.Item>
              </>
            ) : null}
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={handleSubmit}
            loading={isLoading}
          >
            Assign
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

export default AssignNewPhoneNumber;
