import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Form,
  Modal,
  Row,
  Switch,
  Typography,
  notification,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/es/upload/interface";
import { ColumnsType } from "antd/lib/table";
import React, { useState } from "react";
import AdminApi from "../../../api/admin";
import SearchableTable from "../../../components/SearchableTable";
import ShowResponseMessage from "./ShowResponseMessage";

const { Panel } = Collapse;

interface Customer {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

const columns: ColumnsType<Customer> = [
  {
    title: "Name",
    key: "name",
    render: (_, customer) => (
      <Typography.Text>
        {customer.firstName} {customer.lastName}
      </Typography.Text>
    ),
  },
  {
    title: "Phone",
    key: "phone",
    dataIndex: "phone",
  },
  {
    title: "Email",
    key: "email",
    dataIndex: "email",
  },
];

interface UploadModalProps {
  visible: boolean;
  onClose: () => void;
  companyId: string;
  companyName: string;
}

const UploadCustomersModal: React.FC<UploadModalProps> = ({
  visible,
  onClose,
  companyId,
  companyName,
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [preview, setPreview] = useState<boolean>(false);
  const [real, setReal] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, contextHolder] = notification.useNotification();
  const [showPreviewResponse, setShowPreviewResponse] =
    useState<boolean>(false);
  const [previewData, setPreviewData] = useState<any>({});

  const handleFileChange = ({
    fileList,
  }: UploadChangeParam<UploadFile<any>>) => {
    setFileList(fileList.slice(-1));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setShowPreviewResponse(false);
    const file = fileList[0].originFileObj;
    if (!file) return;

    const response = await AdminApi.uploadCustomerFile(
      companyId,
      file as RcFile,
      real,
      preview
    );

    if (preview) {
      setShowPreviewResponse(true);
      setPreviewData(response.message);
    }

    ShowResponseMessage(response, message, real);
    setIsLoading(false);
  };

  return (
    <Modal
      title={`Upload Customers for ${companyName}`}
      visible={visible}
      width={750}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          onClick={onClose}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isLoading}
          disabled={fileList.length === 0}
          onClick={handleSubmit}
        >
          Run
        </Button>,
      ]}
    >
      {contextHolder}
      <Card>
        <Row>
          <Col flex="auto">
            <Form>
              <Form.Item>
                <Dragger
                  fileList={fileList}
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  multiple={false}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single upload only.
                  </p>
                </Dragger>
              </Form.Item>
              <Row
                justify="center"
                align="middle"
                gutter={[16, 24]}
              >
                <Col>
                  <Form.Item label="Preview">
                    <Switch
                      checked={preview}
                      onChange={(checked) => {
                        setPreview(checked);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Real">
                    <Switch
                      defaultChecked
                      onChange={(checked) => {
                        setReal(checked);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {showPreviewResponse && (
          <>
            <Divider />
            <Row style={{ marginTop: 20 }}>
              <Col>
                <Typography.Title level={4}>Preview Data</Typography.Title>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <UpdateCustomersPreview
                  duplicateCustomers={previewData.duplicateCustomers || []}
                  invalidCustomers={previewData.invalidCustomers || []}
                  validCustomers={previewData.validCustomers || []}
                />
              </Col>
            </Row>
          </>
        )}
      </Card>
    </Modal>
  );
};

export default UploadCustomersModal;

const UpdateCustomersPreview: React.FC<{
  duplicateCustomers: Customer[];
  invalidCustomers: Customer[];
  validCustomers: Customer[];
}> = ({ duplicateCustomers, invalidCustomers, validCustomers }) => {
  return (
    <>
      <Collapse style={{ width: "100%" }}>
        <Panel
          header={`Valid Customers: (${validCustomers.length})`}
          key="1"
        >
          <Row>
            <Col span="24">
              <SearchableTable
                columns={columns}
                data={validCustomers}
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          header={`Duplicate Customers: (${duplicateCustomers.length})`}
          key="2"
        >
          <Row>
            <Col span="24">
              <SearchableTable
                columns={columns}
                data={duplicateCustomers}
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          header={`Invalid Customers: (${invalidCustomers.length})`}
          key="3"
        >
          <Row>
            <Col span="24">
              <SearchableTable
                columns={columns}
                data={invalidCustomers}
              />
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};
