import { NotificationInstance } from "antd/es/notification/interface";

export default function ShowResponseMessage(
  response: { success: boolean; message: any },
  message: NotificationInstance,
  real?: boolean
) {
  if (response.success) {
    message.success({
      message: `Success ${
        real === undefined ? "" : real ? "(Real)" : "(Dry Run)"
      }`,
    });
  } else {
    message.error({
      message: "Error",
      description: response.message,
    });
  }
}
