import { Col, Input, Row, Space, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { FC, useEffect, useState } from "react";

interface TableParams {
  pagination?: TablePaginationConfig;
}

const SearchableTable: FC<{
  columns: ColumnsType<any>;
  data: any[];
}> = ({ columns, data }) => {
  const [tableData, setTableData] = useState<any[]>([]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {},
  });

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleSearch = (value: string) => {
    if (!value || value === "") return setTableData(data);

    if (data.length === 0) return setTableData([]);

    const keys = Object.keys(data[0]);

    const filteredData = data.filter((item: any) => {
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        try {
          if (item[key]?.toLowerCase().includes(value.toLowerCase())) {
            return true;
          }
        } catch (e) {
          // do nothing
        }
      }
      return false;
    });

    setTableData(filteredData);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: "100%" }}
      >
        <Row>
          <Col span={24}>
            <Input.Search
              placeholder="Search . . ."
              onSearch={handleSearch}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={tableData}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </Space>
    </>
  );
};

export default SearchableTable;
