import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Switch, Table } from "antd";
import React, { useState } from "react";

interface PhoneNumber {
  id: string;
  phoneNumber: string;
  provider: string;
  createdAt: number;
  deletedAt?: number;
}

interface Props {
  phoneNumbers: PhoneNumber[];
  // eslint-disable-next-line no-unused-vars
  onDeletePhoneNumber: (number: string) => void;
}

const formattedDate = (date: Date) =>
  date.toLocaleDateString("en-us", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

const PhoneNumberTable: React.FC<Props> = ({
  phoneNumbers,
  onDeletePhoneNumber,
}) => {
  const [showRemovedNumbers, setShowRemovedNumbers] = useState<boolean>(false);

  const filteredPhoneNumbers = showRemovedNumbers
    ? phoneNumbers
    : phoneNumbers.filter((p) => !p.deletedAt);

  const columns = [
    {
      title: "Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (phoneNumber: string) => (
        <span>{`(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`}</span>
      ),
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: number) => (
        <span>{formattedDate(new Date(createdAt))}</span>
      ),
    },
    {
      title: "Removed At",
      dataIndex: "deletedAt",
      key: "deletedAt",
      render: (deletedAt: number) => (
        <span>{deletedAt ? formattedDate(new Date(deletedAt)) : "--"}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: PhoneNumber) =>
        record.deletedAt ? (
          <></>
        ) : (
          <Button
            icon={<DeleteOutlined />}
            type="default"
            onClick={() => onDeletePhoneNumber(record.id)}
          >
            Remove
          </Button>
        ),
    },
  ];

  const columnsWithoutRemovedAt = columns.filter(
    (column) => column.key !== "deletedAt"
  );

  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}
    >
      <Row justify="start">
        <Col
          flex={"auto"}
          style={{ textAlign: "right" }}
        >
          <Switch
            checked={showRemovedNumbers}
            onChange={(enabled) => {
              setShowRemovedNumbers(enabled);
            }}
          />{" "}
          Show Removed Numbers{" "}
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Table
            dataSource={filteredPhoneNumbers}
            columns={showRemovedNumbers ? columns : columnsWithoutRemovedAt}
          />
        </Col>
      </Row>
    </Space>
  );
};

export default PhoneNumberTable;
