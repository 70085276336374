// this was stolen from web on Sep 16, 2024
export const formatPhoneNumber = (phoneNumber: string) => {
  let areaCode = "";
  let firstThree = "";
  let lastFour = "";
  // get areaCode
  if (phoneNumber.length >= 3) {
    areaCode = `(${phoneNumber.slice(0, 3)}) `;
  } else {
    areaCode = `(${phoneNumber}`;
  }

  // get firstThree;
  if (phoneNumber.length >= 6) {
    firstThree = phoneNumber.slice(3, 6) + "-";
  } else if (phoneNumber.length > 3 && phoneNumber.length < 6) {
    firstThree = phoneNumber.slice(3, phoneNumber.length);
  }

  // get lastFour
  if (phoneNumber.length > 6) {
    lastFour = phoneNumber.slice(6, phoneNumber.length);
  }

  return `${areaCode}${firstThree}${lastFour}`;
};
