import {
  LoginOutlined,
  UsergroupAddOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Card,
  Col,
  Divider,
  notification,
  Row,
  Space,
  Empty,
  Skeleton,
  Typography,
  Button,
  Drawer,
  Select,
  Input,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Company } from "../Company/data/types";
import { useParams } from "react-router-dom";
import { Organization, User } from "../Company/data/types";
import AdminApi from "../../api/admin";
import CopyToClipboard from "../../components/CopyToClipboard";
import PhoneNumber from "../../components/PhoneNumber";
import { ApiContext, CompanyData } from "../../api/ApiContext";

const OrganizationDescription: React.FC<{ description: string; body: any }> = ({
  description,
  body,
}) => {
  return (
    <>
      <Row>
        <Col style={{ color: "gray" }}>{description}</Col>
      </Row>
      <Row>
        <Col>
          <Typography.Text style={{ textAlign: "left" }}>
            {body}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

const AddCompaniesDrawer: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  companies: CompanyData[];
  onSubmit: (companyIds: string[]) => void;
}> = ({ open, setOpen, companies, onSubmit }) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);

  useEffect(() => {
    setOptions(
      companies.map((company) => ({
        label: company.name,
        value: company.id,
      }))
    );
  }, [companies]);

  const handleClose = () => {
    setOpen(false);
    setSelectedCompanies([]);
    setOptions(
      companies.map((company) => ({
        label: company.name,
        value: company.id,
      }))
    );
  };

  const handleSubmit = () => {
    onSubmit(selectedCompanies);
    handleClose();
  };

  return (
    <Drawer
      title="Add Companies"
      onClose={handleClose}
      open={open}
      footer={
        <Button
          type="primary"
          onClick={handleSubmit}
        >
          Add Companies
        </Button>
      }
    >
      <Select
        value={selectedCompanies}
        mode="multiple"
        options={options}
        onChange={(value) => setSelectedCompanies(value)}
        placeholder="Select companies"
        showSearch
        filterOption={false}
        onSearch={(value) => {
          const filteredOptions = companies
            .filter((company) => {
              return company.name.toLowerCase().includes(value.toLowerCase());
            })
            .map((company) => ({
              label: company.name,
              value: company.id,
            }));
          setOptions(filteredOptions);
        }}
        style={{ width: "100%" }}
      />
    </Drawer>
  );
};

const AddUsersDrawer: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (emailAddresses: string[]) => void;
}> = ({ open, setOpen, onSubmit }) => {
  const [emailAddressesString, setEmailAddressesString] = useState<string>("");

  const handleClose = () => {
    setOpen(false);
    setEmailAddressesString("");
  };

  const handleSubmit = () => {
    onSubmit(emailAddressesString.split(",").map((email) => email.trim()));
    handleClose();
  };

  return (
    <Drawer
      title="Add Users"
      onClose={handleClose}
      open={open}
      footer={
        <Button
          type="primary"
          onClick={handleSubmit}
        >
          Add Users
        </Button>
      }
    >
      <Input.TextArea
        value={emailAddressesString}
        onChange={(e) => setEmailAddressesString(e.target.value)}
        placeholder="Enter email addresses separated by commas"
      />
      <Typography.Text style={{ color: "gray" }}>
        * Email addresses must be associated with an existing ReferPro user
      </Typography.Text>
    </Drawer>
  );
};

const OrganizationView: React.FC = () => {
  const { organizationId } = useParams<{ organizationId: string }>();
  const { fetchCompanies } = useContext(ApiContext);

  const [data, setData] = useState<{
    organization: Organization;
    users: User[];
    companies: Company[];
  } | null>(null);
  const [message, contextHolder] = notification.useNotification();
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addCompaniesDrawerVisible, setAddCompaniesDrawerVisible] =
    useState<boolean>(false);
  const [addUsersDrawerVisible, setAddUsersDrawerVisible] =
    useState<boolean>(false);
  const [allCompanies, setAllCompanies] = useState<CompanyData[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const result = await fetchCompanies();
      if (result) {
        setAllCompanies(result);
      }
      setIsLoading(false);
    };
    fetch();
  }, [fetchCompanies]);

  const handleLoginAs = async (userId: string) => {
    const urlBase = "https://app.referpro.com";
    setIsLoading(true);
    if (!organizationId) {
      message.error({
        message: "Error",
        description: "Company ID is not available.",
      });
      setIsLoading(false);
      return;
    }
    // TODO: Add a way to login as a user with an organizationId
    const response = await AdminApi.loginAs(organizationId, userId);
    setIsLoading(false);
    if (response.success) {
      message.success({
        message: "Success",
        description: (
          <>
            <Divider />
            <Typography.Paragraph>
              Token: {response.accessToken}
            </Typography.Paragraph>
          </>
        ),
      });
      window.open(`${urlBase}/?jwt=${response.accessToken}`, "_blank");
    } else {
      message.error({
        message: "Error",
        description: response.message,
      });
      setIsError(true);
    }
  };

  const handleAddCompanies = async (companyIds: string[]) => {
    try {
      const { message: responseMessage, success } =
        await AdminApi.addCompaniesToOrganization({
          organizationId,
          companyIds,
        });
      if (success) {
        message.success({
          message: responseMessage,
          description: "Companies added",
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error({
          message: "Error",
          description: error.message,
        });
      }
    }
  };

  const handleAddUsers = async (emailAddresses: string[]) => {
    try {
      const { message: responseMessage, success } =
        await AdminApi.addUsersToOrganization({
          organizationId,
          emails: emailAddresses,
        });
      if (success) {
        message.success({
          message: responseMessage,
          description: "Users added",
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error({
          message: "Error",
          description: error.message,
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { organization, users, companies } =
          await AdminApi.getOrganization(organizationId || "");
        setData({ organization, users, companies });
      } catch (e) {
        setIsError(true);
        message.error({
          message: "Error",
          description: "Failed to fetch organization data",
        });
      }
    };
    fetchData();
  }, [organizationId, message]);

  return (
    <>
      {contextHolder}
      <Breadcrumb
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "0 20px",
          marginTop: "20px",
        }}
        separator=">"
        items={[
          {
            title: "Organizations",
            href: "/organizations",
          },
          {
            title: data ? `${data.organization.name}` : "Organization Page",
          },
        ]}
      />
      <Card style={{ margin: 20 }}>
        {data === null && <Skeleton loading={true} />}
        {isError && <Empty description="Uh oh!" />}
        {!isError && data !== null && (
          <Space
            direction="vertical"
            style={{ width: "100%" }}
          >
            <Row>
              <Col>
                <Typography.Title level={2}>
                  {data.organization.name}
                </Typography.Title>
              </Col>
            </Row>
            <Row justify="start">
              <Col style={{ borderRadius: 5, border: "1px dotted gray" }}>
                <CopyToClipboard textToCopy={data.organization.id} />
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={12}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>Details</Typography.Title>
                  </Row>
                  <OrganizationDescription
                    description="Description"
                    body={data.organization.description}
                  />
                  <OrganizationDescription
                    description="Created At"
                    body={new Date(
                      data.organization.createdAt
                    ).toLocaleString()}
                  />
                </Space>
              </Col>
              <Col span={12}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>Actions</Typography.Title>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        style={{ width: 200 }}
                        icon={<PlusCircleOutlined />}
                        onClick={() => setAddCompaniesDrawerVisible(true)}
                        type="default"
                      >
                        Add Companies
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        style={{ width: 200 }}
                        icon={<UsergroupAddOutlined />}
                        onClick={() => setAddUsersDrawerVisible(true)}
                        type="default"
                      >
                        Add Users
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>
                      Users ({data.users.length})
                    </Typography.Title>
                  </Row>
                  <Row>
                    {data.users.map((user) => (
                      <Col
                        span={8}
                        key={user.id}
                        style={{ marginBottom: data.users.length > 3 ? 75 : 0 }}
                      >
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                        >
                          <Row>
                            <Col style={{ color: "gray" }}>Name</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>
                                {user.firstName} {user.lastName}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ color: "gray" }}>Phone</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>
                                <PhoneNumber value={user.phone} />
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ color: "gray" }}>Email</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>{user.email}</Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ color: "gray" }}>Created At</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>
                                {new Date(user.createdAt).toLocaleString()}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                style={{ width: 200 }}
                                icon={<LoginOutlined />}
                                onClick={() => handleLoginAs(user.id)}
                                loading={isLoading}
                                type="primary"
                              >
                                Login As
                              </Button>
                            </Col>
                          </Row>
                        </Space>
                      </Col>
                    ))}
                  </Row>
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>
                      Companies ({data.companies.length})
                    </Typography.Title>
                  </Row>
                  <Row>
                    {data.companies.map((company) => (
                      <Col
                        span={8}
                        key={company.id}
                        style={{
                          marginBottom: data.companies.length > 3 ? 75 : 0,
                        }}
                      >
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                        >
                          <Row>
                            <Col style={{ color: "gray" }}>Name</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>{company.name}</Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ color: "gray" }}>Created At</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>
                                {new Date(company.createdAt).toLocaleString()}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Space>
                      </Col>
                    ))}
                  </Row>
                </Space>
              </Col>
            </Row>
          </Space>
        )}
      </Card>
      {addCompaniesDrawerVisible && (
        <AddCompaniesDrawer
          open={addCompaniesDrawerVisible}
          setOpen={setAddCompaniesDrawerVisible}
          companies={allCompanies.filter(
            (company) => !data?.companies.map((c) => c.id).includes(company.id)
          )}
          onSubmit={handleAddCompanies}
        />
      )}
      {addUsersDrawerVisible && (
        <AddUsersDrawer
          open={addUsersDrawerVisible}
          setOpen={setAddUsersDrawerVisible}
          onSubmit={handleAddUsers}
        />
      )}
    </>
  );
};

export default OrganizationView;
