import styled from "@emotion/styled";
import { Button, Col, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SigninApi from "../../api/signin";
import Toast from "../../components/Toast";

const SigninStyle = styled.div({
  "&.signinRoot": {},
});

// eslint-disable-next-line no-unused-vars
const Signin: React.FC<{ onSignIn: (token: string) => void }> = ({
  onSignIn,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [isSigningIn, setIsSigningIn] = useState(false);

  const onFinish = async () => {
    setIsSigningIn(true);
    try {
      const response = await SigninApi.verifyEmailPassword(email, password);
      if (response.accessToken) {
        onSignIn(response.accessToken);
        navigate("/companies");
      }
    } catch (e) {
      Toast("error", `Error signing in with email: ${email}`);
    } finally {
      setIsSigningIn(false);

      // onSignIn("foo");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    // eslint-disable-next-line no-console
    console.log("Failed:", errorInfo);
  };

  return (
    <SigninStyle className="signinRoot">
      <Row
        justify="center"
        style={{ marginTop: 50 }}
      >
        <Col
          xs={{ span: 18 }}
          sm={{ span: 12 }}
          md={{ span: 10 }}
          lg={{ span: 8 }}
          xl={{ span: 6 }}
        >
          <Form
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                placeholder="Email"
                onChange={handleEmailChange}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                placeholder="Password"
                onChange={handlePasswordChange}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSigningIn}
              >
                Sign in
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </SigninStyle>
  );
};

export default Signin;
