import { PlusCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  Card,
  Col,
  Input,
  Row,
  Space,
  Table,
  Button,
  Flex,
  Select,
  Drawer,
  notification,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiContext, CompanyData } from "../../api/ApiContext";
import AdminApi from "../../api/admin";

const { Search } = Input;

const CreateOrganizationDrawer: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
  companies: CompanyData[];
  onSubmit: ({
    name,
    companyIds,
    description,
  }: {
    name: string;
    companyIds: string[];
    description: string;
  }) => void;
}> = ({ open, setOpen, companies, onSubmit }) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    setOptions(
      companies.map((company) => ({
        label: company.name,
        value: company.id,
      }))
    );
  }, [companies]);

  const handleClose = () => {
    setOpen(false);
    setSelectedCompanies([]);
    setName("");
    setDescription("");
    setOptions(
      companies.map((company) => ({
        label: company.name,
        value: company.id,
      }))
    );
  };

  const handleSubmit = () => {
    onSubmit({ name, companyIds: selectedCompanies, description });
    handleClose();
  };

  return (
    <Drawer
      title="Create Organization"
      onClose={handleClose}
      open={open}
      footer={
        <Button
          type="primary"
          onClick={handleSubmit}
        >
          Create Organization
        </Button>
      }
    >
      <Flex
        style={{
          flexDirection: "column",
        }}
        gap={16}
      >
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Organization Name"
        />
        <Input.TextArea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Organization Description"
        />
        <Select
          value={selectedCompanies}
          mode="multiple"
          options={options}
          onChange={(value) => setSelectedCompanies(value)}
          placeholder="Select companies"
          showSearch
          filterOption={false}
          onSearch={(value) => {
            const filteredOptions = companies
              .filter((company) => {
                return company.name.toLowerCase().includes(value.toLowerCase());
              })
              .map((company) => ({
                label: company.name,
                value: company.id,
              }));
            setOptions(filteredOptions);
          }}
          style={{ width: "100%" }}
        />
      </Flex>
    </Drawer>
  );
};

const OrganizationsStyle = styled.div({
  "&.organizationsStyleRoot": {
    ".ant-table-row": {
      cursor: "pointer",
    },
  },
});

function formatTimestamp(timestamp: number): string {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function padZero(num: number): string {
  return num.toString().padStart(2, "0");
}

const Organizations: React.FC = () => {
  const navigate = useNavigate();

  const [message] = notification.useNotification();

  const [organizationsList, setOrganizationsList] = useState<any>([]);
  const [tableData, setTableDataWithKeys] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createOrganizationDrawerVisible, setCreateOrganizationDrawerVisible] =
    useState<boolean>(false);

  const { fetchOrganizations, fetchCompanies } = useContext(ApiContext);

  const [allCompanies, setAllCompanies] = useState<CompanyData[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const result = await fetchCompanies();
      if (result) {
        setAllCompanies(result);
      }
      setIsLoading(false);
    };
    fetch();
  }, [fetchCompanies]);

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      const result = await fetchOrganizations();
      if (result) {
        setOrganizationsList(result);
        setTableData(result);
      }
      setIsLoading(false);
    };
    fetch();
  }, [fetchOrganizations]);

  const handleCreateOrganization = async (data: {
    name: string;
    companyIds: string[];
    description: string;
  }) => {
    try {
      const { message: responseMessage, success } =
        await AdminApi.createOrganization(data);
      if (success) {
        message.success({
          message: responseMessage,
          description: "Company Activation is Successful",
        });
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error({
          message: "Error",
          description: error.message,
        });
      } else {
        message.error({
          message: "Unknown Error",
          description: "An unknown error occurred.",
        });
      }
    }
  };

  const handleSearch = (value: string) => {
    if (!value || value === "") return setTableData(organizationsList);

    const filteredData = (organizationsList || []).filter(
      (item: any) =>
        item.name?.toLowerCase().includes(value.toLowerCase()) ||
        item.domain?.toLowerCase().includes(value.toLowerCase()) ||
        item.id?.toLowerCase().includes(value.toLowerCase())
    );
    setTableData(filteredData);
  };

  const setTableData = (data: any[]) => {
    setTableDataWithKeys(
      data.map((organization) => ({
        ...organization,
        key: organization.id,
      }))
    );
  };

  return (
    <OrganizationsStyle className="organizationsStyleRoot">
      <Card style={{ margin: 20 }}>
        <Space
          direction="vertical"
          style={{ width: "100%" }}
        >
          <Row>
            <Col span={24}>
              <Flex
                align="center"
                justify="space-between"
                gap={16}
              >
                <Search
                  placeholder="Search by name or website"
                  onSearch={handleSearch}
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{ display: "flex", flex: 1 }}
                />
                <Row>
                  <Col>
                    <Button
                      style={{ width: 200 }}
                      icon={<PlusCircleOutlined />}
                      onClick={() => setCreateOrganizationDrawerVisible(true)}
                      type="default"
                    >
                      Create Organization
                    </Button>
                  </Col>
                </Row>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    sorter: (a, b) => a.name.localeCompare(b.name),
                    sortDirections: ["ascend", "descend"],
                  },
                  {
                    title: "Created At",
                    dataIndex: "createdAt",
                    key: "createdAt",
                    sorter: (a, b) => (a.createdAt < b.createdAt ? -1 : 1),
                    sortDirections: ["ascend", "descend"],
                    render: (text: any) => formatTimestamp(text),
                  },
                ]}
                dataSource={tableData}
                pagination={false}
                className="ant-border-space"
                loading={isLoading}
                onRow={(organization) => ({
                  onClick: () => navigate("/organization/" + organization.id),
                })}
              />
            </Col>
          </Row>
        </Space>
      </Card>
      <CreateOrganizationDrawer
        open={createOrganizationDrawerVisible}
        setOpen={setCreateOrganizationDrawerVisible}
        companies={allCompanies}
        onSubmit={handleCreateOrganization}
      />
    </OrganizationsStyle>
  );
};

export default Organizations;
