import { Col, Layout, Row, Typography } from "antd";
import React from "react";
import Signin from "../pages/Signin/Signin";

const { Header, Content } = Layout;
const { Text } = Typography;

// eslint-disable-next-line no-unused-vars
const SignedOutLayout: React.FC<{ onSignIn: (apiToken: string) => void }> = ({
  onSignIn,
}) => {
  return (
    <>
      <Header style={{ backgroundColor: "rgb(3,21,39)" }}>
        <Row>
          <Col
            className="gutter-row"
            style={{ color: "white" }}
          >
            <Text
              strong
              style={{ color: "white" }}
            >
              ReferPro Admin
            </Text>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Content>
          <Signin onSignIn={onSignIn} />
        </Content>
      </Layout>
    </>
  );
};

export default SignedOutLayout;
