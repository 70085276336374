import axios from "axios";
import { BASE_URL } from "../constants";

export default class Signin {
  static async verifyEmailPassword(email: string, password: string) {
    const response = await axios.post(`${BASE_URL}/login/admin`, {
      email,
      password,
    });
    return response.data;
  }
}
