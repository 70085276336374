import { RcFile } from "antd/lib/upload/interface";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, REFERPRO_ADMIN_TOKEN_NAME } from "../constants";
import { OrganizationData } from "./ApiContext";
import { Company, Organization, User } from "../pages/Company/data/types";

class Log {
  static info(message: string) {
    // eslint-disable-next-line no-console
    console.log(message);
  }

  static error(message: string | unknown) {
    // eslint-disable-next-line no-console
    console.error(message);
  }
}

export default class Admin {
  static async listCompanies() {
    const { companies } = await Admin.get("companies");
    return companies;
  }

  static async listOrganizations() {
    const { organizations } = await Admin.get("organizations");
    return organizations;
  }

  static async getCompany(companyId: string) {
    const { company } = await Admin.get(`company/${companyId}`);
    return company;
  }

  static async getOrganization(organizationId: string) {
    const { organization, users, companies } = await Admin.get(
      `organization/${organizationId}`
    );
    return { organization, users, companies };
  }

  static async createOrganization({
    name,
    description,
    companyIds,
  }: {
    name: string;
    description: string;
    companyIds: string[];
  }) {
    try {
      const { success, message } = await Admin.post("organization", {
        name,
        description,
        companyIds,
      });
      return { success, message };
    } catch (error: unknown) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async addCompaniesToOrganization({
    organizationId,
    companyIds,
  }: {
    organizationId?: string;
    companyIds: string[];
  }) {
    if (!organizationId) {
      return { success: false, message: "Organization ID is required" };
    }
    try {
      const { success, message } = await Admin.post(`organization/company`, {
        organizationId,
        companyIds,
      });
      return { success, message };
    } catch (error: unknown) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async addUsersToOrganization({
    organizationId,
    emails,
  }: {
    organizationId?: string;
    emails: string[];
  }) {
    if (!organizationId) {
      return { success: false, message: "Organization ID is required" };
    }
    try {
      for (const email of emails) {
        try {
          await Admin.post(`organization/user`, { organizationId, email });
        } catch (error: unknown) {
          Log.error(error);
          if (axios.isAxiosError(error)) {
            Log.error(error.response?.data.error);
            return { success: false, message: error.response?.data.error };
          }
        }
      }
      return { success: true, message: "Users added" };
    } catch (error: unknown) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async getOptOutRate(companyId: string) {
    const { optOutRate } = await Admin.get(`message-summary/${companyId}`);
    return optOutRate;
  }

  static async getMessagesSent(companyId: string, days: number) {
    const { messagesSent } = await Admin.get(`messages-sent/${companyId}`, {
      params: {
        days,
      },
    });
    return messagesSent;
  }

  static async getCampaignGroupSummaries(companyId: string) {
    const { campaignGroupSummary } = await Admin.get(
      `campaign-group-summary/${companyId}`
    );
    return campaignGroupSummary;
  }

  static async activateCompany(companyId: string) {
    try {
      const { success, message } = await Admin.post("activate", {
        companyId,
      });
      return { success, message };
    } catch (error: unknown) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async deactivateCompany(companyId: string) {
    try {
      const { success, message } = await Admin.post("deactivate", {
        companyId,
      });
      return { success, message };
    } catch (error: unknown) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async uploadCustomerFile(
    companyId: string,
    file: RcFile,
    real: boolean,
    preview: boolean
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("companyId", companyId);
    formData.append("real", `${real}`);
    formData.append("preview", `${preview}`);

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Admin.getAccessToken()}`,
      },
    };

    try {
      const { success, message } = await Admin.post(
        "upload/customers",
        formData,
        config
      );
      return { success, message };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async assignPhoneNumber(
    companyId: string,
    phoneNumber: string,
    provider: string,
    accountSid?: string,
    authToken?: string,
    messagingServiceId?: string,
    applicationId?: string
  ) {
    try {
      const response = await Admin.post("phoneNumber", {
        companyId,
        phoneNumber,
        provider,
        accountSid,
        authToken,
        messagingServiceId,
        applicationId,
      });
      return { success: true, message: response.message };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async assignYoutubeVideoId(companyId: string, youtubeVideoId: string) {
    try {
      const response = await Admin.post(
        `company/${companyId}/youtube-video-id`,
        { videoId: youtubeVideoId }
      );
      return { success: true, message: response.message };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async assignSendgridTemplateId(
    companyId: string,
    sendgridTemplateId: string
  ) {
    try {
      const response = await Admin.post(
        `company/${companyId}/sendgrid-template-id`,
        { templateId: sendgridTemplateId }
      );
      return { success: true, message: response.message };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async assignEmailToSendCampaignsFrom(
    companyId: string,
    emailToSendFrom: string
  ) {
    if (emailToSendFrom === "") {
      return { success: false, message: "Email is required" };
    }
    try {
      const response = await Admin.post(
        `company/${companyId}/email-to-send-from`,
        { emailToSendFrom }
      );
      return { success: true, message: response.message };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async listAssignedPhoneNumbers(companyId: string) {
    try {
      const { assignedNumbers } = await Admin.get(
        `phoneNumber/company/${companyId}`
      );
      return { success: true, message: assignedNumbers };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async deleteAssignedPhoneNumber(id: string) {
    try {
      const { phoneNumber } = await Admin.delete(`phoneNumber/${id}`);
      return { success: !!phoneNumber.deletedAt, phoneNumber };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async listConversations(companyId: string, search?: string) {
    try {
      const { conversations } = await Admin.get(`conversations/${companyId}`, {
        params: {
          search: search ?? "",
          limit: 10,
        },
      });
      return { success: true, conversations };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, conversations: [] };
    }
  }

  static async getConversation(conversationId: string) {
    try {
      const { conversation, messages } = await Admin.get(
        `conversation/${conversationId}`
      );
      return { success: true, conversation, messages };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async sendConversationMessage(
    conversationId: string,
    message: string
  ) {
    try {
      await Admin.post(`conversation/${conversationId}`, { message });
      return { success: true };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false };
    }
  }

  static async loginAs(companyId: string, userId: string) {
    try {
      const { accessToken, tokenType } = await Admin.post("assume", {
        companyId,
        userId,
      });
      return { success: true, accessToken, tokenType };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async searchCustomers(companyId: string, searchText: string) {
    try {
      const { customers } = await Admin.get(
        `customers/${companyId}/${searchText}`
      );
      return { success: true, customers };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, customers: [] };
    }
  }

  static async submitReferral(
    companyId: string,
    customerId: string,
    referral: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      notes: string;
    },
    type: "SELF" | "STANDARD"
  ) {
    try {
      const response = await Admin.post(`referrals/create/${customerId}`, {
        firstName: referral.firstName,
        lastName: referral.lastName,
        email: referral.email,
        phone: referral.phone,
        message: referral.notes,
        type,
      });
      return { success: true, response };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async optOutCustomer(companyId: string, phoneNumber: string) {
    try {
      const response = await Admin.post(
        `phone-number/${companyId}/${phoneNumber}/opt-out`
      );
      return { success: true, message: response.message };
    } catch (error) {
      Log.error(error);
      if (axios.isAxiosError(error)) {
        Log.error(error.response?.data.error);
        return { success: false, message: error.response?.data.error };
      }
      return { success: false, message: "Error" };
    }
  }

  static async get<T = any>(
    path: string,
    optionalConfig?: AxiosRequestConfig<any>
  ) {
    const config: AxiosRequestConfig = {
      headers: Admin.getHeaders(),
      ...optionalConfig,
    };

    const response = await axios.get<T>(`${BASE_URL}/admin/${path}`, config);
    return response.data;
  }

  static async post<T = any>(
    path: string,
    body = {},
    optionalConfig?: AxiosRequestConfig<any>
  ) {
    const config = {
      headers: Admin.getHeaders(),
      ...optionalConfig,
    };

    const response = await axios.post<T>(
      `${BASE_URL}/admin/${path}`,
      body,
      config
    );
    return response.data;
  }

  static async delete<T = any>(
    path: string,
    optionalConfig?: AxiosRequestConfig<any>
  ) {
    const config: AxiosRequestConfig = {
      headers: Admin.getHeaders(),
      ...optionalConfig,
    };

    const response = await axios.delete<T>(`${BASE_URL}/admin/${path}`, config);
    return response.data;
  }

  static getHeaders() {
    return {
      Authorization: `Bearer ${Admin.getAccessToken()}`,
    };
  }

  static getAccessToken() {
    return localStorage.getItem(REFERPRO_ADMIN_TOKEN_NAME);
  }
}
