import {
  CheckCircleOutlined,
  DeleteOutlined,
  LoginOutlined,
  PhoneOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Input,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Spin,
  Typography,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AdminApi from "../../api/admin";
import BarChart from "../../components/BarChart";
import CopyToClipboard from "../../components/CopyToClipboard";
import CustomerReferrals from "../../components/Customers";
import MessageInbox from "../../components/MessageInbox";
import PhoneNumber from "../../components/PhoneNumber";
import AssignPhoneNumberModal from "./components/AssignPhoneNumberModal";
import UploadCustomersModal from "./components/UploadCustomersModal";
import {
  CampaignGroupSummary,
  Company,
  MessagesSent,
  Referral,
  Reporting,
  Settings,
  User,
} from "./data/types";
import { formatChartData } from "./data/utils";

const CompanyDescription: React.FC<{ description: string; body: any }> = ({
  description,
  body,
}) => {
  return (
    <>
      <Row>
        <Col style={{ color: "gray" }}>{description}</Col>
      </Row>
      <Row>
        <Col>
          <Typography.Text style={{ textAlign: "left" }}>
            {body}
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

const CompanyView: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const [data, setData] = useState<{
    company: Company;
    users: User[];
    reporting: Reporting;
    referrals: Referral[];
    settings: Settings;
  } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, contextHolder] = notification.useNotification();
  const [isUploadModalVisible, setIsUploadModalVisible] =
    useState<boolean>(false);
  const [isAssignPhoneNumberModalVisible, setIsAssignPhoneNumberModalVisible] =
    useState<boolean>(false);

  const [youtubeVideoId, setYoutubeVideoId] = useState<string>("");
  const [sendgridTemplateId, setSendgridTemplateId] = useState<string>("");
  const [emailToSendFrom, setEmailToSendFrom] = useState<string>("");
  const [optOutRate, setOptOutRate] = useState<string>("");
  const [loadingOptOutRate, setLoadingOptOutRate] = useState<boolean>(false);
  const [chartData, setChartData] = useState<MessagesSent>([]);
  const [loadingChartData, setLoadingChartData] = useState<boolean>(false);
  const [campaignGroupSummaries, setCampaignGroupSummaries] = useState<
    CampaignGroupSummary[]
  >([]);
  const [loadingCampaignGroupSummaries, setLoadingCampaignGroupSummaries] =
    useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      // Logic to fetch company data
      try {
        if (companyId) {
          const { company, users, referrals, reporting, settings } =
            await AdminApi.getCompany(companyId);
          setData({ company, users, referrals, reporting, settings });
          if (settings.referral.youtubeVideoId)
            setYoutubeVideoId(settings.referral.youtubeVideoId);
          if (settings.referral.sendgridTemplateId)
            setSendgridTemplateId(settings.referral.sendgridTemplateId);
        }
      } catch (e) {
        message.error({
          message: "Error",
          description: "Failed to fetch company data",
        });
      }
    };
    fetchData();
  }, [companyId, message]);

  useEffect(() => {
    if (companyId) {
      setLoadingOptOutRate(true);
      AdminApi.getOptOutRate(companyId).then((data) => {
        if (data) {
          setOptOutRate(data);
        }
        setLoadingOptOutRate(false);
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      setLoadingChartData(true);
      const days = 14;
      AdminApi.getMessagesSent(companyId, days).then((data) => {
        if (data) {
          setChartData(formatChartData(data, days));
        }
        setLoadingChartData(false);
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      setLoadingCampaignGroupSummaries(true);
      AdminApi.getCampaignGroupSummaries(companyId).then((data) => {
        if (data) {
          setCampaignGroupSummaries(data);
        }
        setLoadingCampaignGroupSummaries(false);
      });
    }
  }, [companyId]);

  const handleClose = () => {
    setIsUploadModalVisible(false);
  };

  const handleAssignPhoneNumberModalClose = () => {
    setIsAssignPhoneNumberModalVisible(false);
  };

  const handleActivateCompany = async (companyId: string) => {
    try {
      if (companyId) {
        const { message: responseMessage, success } =
          await AdminApi.activateCompany(companyId);
        if (success) {
          message.success({
            message: responseMessage,
            description: "Company Activation is Successful",
          });
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error({
          message: "Error",
          description: error.message,
        });
      } else {
        message.error({
          message: "Unknown Error",
          description: "An unknown error occurred.",
        });
      }
      setIsError(true);
    }
  };

  const handleLoginAs = async (userId: string) => {
    const urlBase = "https://app.referpro.com";
    setIsLoading(true);
    if (!companyId) {
      message.error({
        message: "Error",
        description: "Company ID is not available.",
      });
      setIsLoading(false);
      return;
    }
    const response = await AdminApi.loginAs(companyId, userId);
    setIsLoading(false);
    if (response.success) {
      message.success({
        message: "Success",
        description: (
          <>
            <Divider />
            <Typography.Paragraph>
              Token: {response.accessToken}
            </Typography.Paragraph>
          </>
        ),
      });
      window.open(`${urlBase}/?jwt=${response.accessToken}`, "_blank");
    } else {
      message.error({
        message: "Error",
        description: response.message,
      });
      setIsError(true);
    }
  };

  const handleDeactivateCompany = async () => {
    try {
      if (companyId) {
        const { message: responseMessage, success } =
          await AdminApi.deactivateCompany(companyId);
        if (success) {
          message.success({
            message: responseMessage,
            description: "Company deactivation is Successful",
          });
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        message.error({
          message: "Error",
          description: error.message,
        });
      } else {
        message.error({
          message: "Unknown Error",
          description: "An unknown error occurred.",
        });
      }
      setIsError(true);
    }
  };

  return (
    <>
      {contextHolder}
      <Breadcrumb
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "0 20px",
          marginTop: "20px",
        }}
        separator=">"
        items={[
          {
            title: "Companies",
            href: "/companies",
          },
          {
            title: data ? `${data.company.name}` : "Company Page",
          },
        ]}
      />
      <Card style={{ margin: 20 }}>
        {data === null && <Skeleton loading={true} />}
        {isError && <Empty description="Uh oh!" />}
        {!isError && data !== null && (
          <Space
            direction="vertical"
            style={{ width: "100%" }}
          >
            <Row>
              <Col>
                <Typography.Title level={2}>
                  {data.company.name}
                </Typography.Title>
              </Col>
            </Row>
            <Row justify="start">
              <Col style={{ borderRadius: 5, border: "1px dotted gray" }}>
                <CopyToClipboard textToCopy={data.company.id} />
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={12}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>Details</Typography.Title>
                  </Row>
                  <CompanyDescription
                    description="Domain"
                    body={
                      <a href={data.company.domain}>{data.company.domain}</a>
                    }
                  />
                  <CompanyDescription
                    description="Created At"
                    body={new Date(data.company.createdAt).toLocaleString()}
                  />
                  <CompanyDescription
                    description="YouTube Video Id"
                    body={
                      <Space
                        direction="horizontal"
                        size="small"
                      >
                        <Input
                          size="small"
                          value={youtubeVideoId}
                          onChange={(event) =>
                            setYoutubeVideoId(event.target.value)
                          }
                          placeholder="Enter YouTube Video ID"
                        />
                        <Button
                          size="small"
                          type="primary"
                          onClick={async () => {
                            // Logic to save SendGrid template id
                            let response = await AdminApi.assignYoutubeVideoId(
                              data.company.id,
                              youtubeVideoId
                            );
                            if (response.success) {
                              setData({
                                referrals: data.referrals,
                                company: data.company,
                                reporting: data.reporting,
                                settings: {
                                  ...data.settings,
                                  referral: {
                                    ...data.settings.referral,
                                    youtubeVideoId,
                                  },
                                },
                                users: data.users,
                              });
                            }
                          }}
                          disabled={
                            youtubeVideoId ===
                            data.settings.referral.youtubeVideoId
                          } // Button active only if there's input
                        >
                          Save
                        </Button>
                      </Space>
                    }
                  />
                  <CompanyDescription
                    description="Sendgrid Template Id"
                    body={
                      <Space
                        direction="horizontal"
                        size="small"
                      >
                        <Input
                          size="small"
                          value={sendgridTemplateId}
                          onChange={(event) =>
                            setSendgridTemplateId(event.target.value)
                          }
                          placeholder="Enter SendGrid Template ID"
                        />
                        <Button
                          size="small"
                          type="primary"
                          onClick={async () => {
                            // Logic to save SendGrid template id
                            let response =
                              await AdminApi.assignSendgridTemplateId(
                                data.company.id,
                                sendgridTemplateId
                              );
                            if (response.success) {
                              setData({
                                referrals: data.referrals,
                                company: data.company,
                                reporting: data.reporting,
                                settings: {
                                  ...data.settings,
                                  referral: {
                                    ...data.settings.referral,
                                    sendgridTemplateId,
                                  },
                                },
                                users: data.users,
                              });
                            }
                          }}
                          disabled={
                            sendgridTemplateId ===
                            data.settings.referral.sendgridTemplateId
                          } // Button active only if there's input
                        >
                          Save
                        </Button>
                      </Space>
                    }
                  />
                  <CompanyDescription
                    description="Email To Send Campaigns From"
                    body={
                      <Space
                        direction="horizontal"
                        size="small"
                      >
                        <Input
                          size="small"
                          value={emailToSendFrom}
                          onChange={(event) =>
                            setEmailToSendFrom(event.target.value)
                          }
                          placeholder="Enter email to send campaigns from"
                        />
                        <Button
                          size="small"
                          type="primary"
                          onClick={async () => {
                            // Logic to save SendGrid template id
                            let response =
                              await AdminApi.assignEmailToSendCampaignsFrom(
                                data.company.id,
                                emailToSendFrom
                              );
                            if (response.success) {
                              setData({
                                referrals: data.referrals,
                                company: data.company,
                                reporting: data.reporting,
                                settings: {
                                  ...data.settings,
                                  referral: {
                                    ...data.settings.referral,
                                    emailToSendFrom,
                                  },
                                },
                                users: data.users,
                              });
                            }
                          }}
                          disabled={
                            emailToSendFrom === "" ||
                            emailToSendFrom ===
                              data.settings.referral.emailToSendFrom
                          } // Button active only if there's input
                        >
                          Save
                        </Button>
                      </Space>
                    }
                  />
                </Space>
              </Col>
              <Col span={12}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>Actions</Typography.Title>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        style={{ width: 200 }}
                        icon={<UsergroupAddOutlined />}
                        onClick={() => setIsUploadModalVisible(true)}
                        type="default"
                      >
                        Upload Customers
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        style={{ width: 200 }}
                        icon={<CheckCircleOutlined />}
                        onClick={() => {
                          if (companyId) {
                            handleActivateCompany(companyId as string);
                          }
                        }}
                        type="default"
                      >
                        Activate Company
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        style={{ width: 200 }}
                        icon={<PhoneOutlined />}
                        onClick={() => setIsAssignPhoneNumberModalVisible(true)}
                        type="default"
                      >
                        Assign Phone Number
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure you want to deactivate this company?"
                        onConfirm={handleDeactivateCompany}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          style={{
                            width: 200,
                          }}
                          danger
                          icon={<DeleteOutlined />}
                          type="default"
                        >
                          Deactivate
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>
                      Usage Statistics
                    </Typography.Title>
                  </Row>
                  <Row justify="start">
                    <Col span={4}>
                      <Row>
                        <Col style={{ color: "gray" }}>Number of Referrals</Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography.Text>
                            {data.referrals.length}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={4}>
                      <Row>
                        <Col style={{ color: "gray" }}>Number of Customers</Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography.Text>
                            {data.reporting.customerCount}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Space
                    direction="vertical"
                    style={{ width: "100%" }}
                  >
                    <Row>
                      <Typography.Title level={5}>
                        Campaign Groups
                      </Typography.Title>
                    </Row>
                    {loadingCampaignGroupSummaries ? (
                      <Row
                        justify="center"
                        align="middle"
                        style={{ height: 300 }}
                      >
                        <Spin />
                      </Row>
                    ) : (
                      campaignGroupSummaries.map((campaignGroup) => {
                        return (
                          <div key={campaignGroup.campaignGroupId}>
                            <Row>
                              <Col style={{ color: "gray" }}>
                                {campaignGroup.campaignGroupName}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Typography.Text>
                                  {campaignGroup.customerCount}
                                </Typography.Text>
                              </Col>
                            </Row>
                          </div>
                        );
                      })
                    )}
                  </Space>
                  <Row>
                    <Typography.Title level={5}>Messaging</Typography.Title>
                  </Row>
                  <Row>
                    <Col style={{ color: "gray" }}>Opt Out % (Lifetime)</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Typography.Text>
                        {loadingOptOutRate ? <Spin /> : optOutRate}
                      </Typography.Text>
                    </Col>
                  </Row>

                  <Row>
                    <Typography.Title level={5}>History</Typography.Title>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {loadingChartData ? (
                        <Row
                          justify="center"
                          align="middle"
                          style={{ height: 300 }}
                        >
                          <Spin />
                        </Row>
                      ) : (
                        <BarChart
                          id="messages-sent-bar-chart"
                          xAxisLabels={chartData.map((data) => data.day)}
                          seriesData={[
                            {
                              name: "SMS Sent",
                              data: chartData.map((data) => data.totalMessages),
                              itemStyle: {
                                color: "#1677ff",
                              },
                            },
                          ]}
                        />
                      )}
                    </Col>
                  </Row>
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                {companyId && <MessageInbox companyId={companyId} />}
              </Col>
            </Row>

            <Divider />
            <Col span={24}>
              {companyId && <CustomerReferrals companyId={companyId} />}
            </Col>
            <Divider />

            <Row>
              <Col span={24}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>
                      Users ({data.users.length})
                    </Typography.Title>
                  </Row>
                  <Row>
                    {data.users.map((user) => (
                      <Col
                        span={8}
                        key={user.id}
                        style={{ marginBottom: data.users.length > 3 ? 75 : 0 }}
                      >
                        <Space
                          direction="vertical"
                          style={{ width: "100%" }}
                        >
                          <Row>
                            <Col style={{ color: "gray" }}>Name</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>
                                {user.firstName} {user.lastName}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ color: "gray" }}>Phone</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>
                                <PhoneNumber value={user.phone} />
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ color: "gray" }}>Email</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>{user.email}</Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ color: "gray" }}>Created At</Col>
                          </Row>
                          <Row>
                            <Col>
                              <Typography.Text>
                                {new Date(user.createdAt).toLocaleString()}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button
                                style={{ width: 200 }}
                                icon={<LoginOutlined />}
                                onClick={() => handleLoginAs(user.id)}
                                loading={isLoading}
                                type="primary"
                              >
                                Login As
                              </Button>
                            </Col>
                          </Row>
                        </Space>
                      </Col>
                    ))}
                  </Row>
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                <Space
                  direction="vertical"
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Typography.Title level={4}>
                      Referral Settings
                    </Typography.Title>
                  </Row>
                  <Row justify="space-between">
                    <Col span="10">
                      <Space
                        direction="vertical"
                        style={{ width: "100%" }}
                      >
                        <Row>
                          <Col>
                            <Typography.Title level={5}>
                              SMS / Email Campaign Messaging
                            </Typography.Title>
                          </Col>
                        </Row>
                        <CompanyDescription
                          description="Greeting"
                          body={data.settings.referral.customMessage.greeting}
                        />

                        <CompanyDescription
                          description="Body"
                          body={data.settings.referral.customMessage.body}
                        />

                        <CompanyDescription
                          description="Banner"
                          body={data.settings.referral.customMessage.banner}
                        />
                      </Space>
                    </Col>
                    <Col span="10">
                      <Row>
                        <Col>
                          <Typography.Title level={5}>
                            Discount Messaging
                          </Typography.Title>
                        </Col>
                      </Row>
                      <CompanyDescription
                        description="Discount Message"
                        body={data.settings.referral.customDiscountMessage.body}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Typography.Title level={5}>
                        New Referral Alert Message
                      </Typography.Title>
                    </Col>
                  </Row>
                  <CompanyDescription
                    description="Greeting"
                    body={
                      data.settings.referral.customNewReferralMessage.greeting
                    }
                  />

                  <CompanyDescription
                    description="Body"
                    body={data.settings.referral.customNewReferralMessage.body}
                  />

                  <CompanyDescription
                    description="Is Enabled"
                    body={
                      data.settings.referral.customNewReferralMessage.enabled
                        ? "Yes"
                        : "No"
                    }
                  />
                </Space>
              </Col>
            </Row>
          </Space>
        )}
        {data && companyId && (
          <UploadCustomersModal
            visible={isUploadModalVisible}
            onClose={handleClose}
            companyId={companyId}
            companyName={data.company.name}
          />
        )}
        {data && companyId && (
          <AssignPhoneNumberModal
            visible={isAssignPhoneNumberModalVisible}
            onClose={handleAssignPhoneNumberModalClose}
            companyId={companyId}
            companyName={data.company.name}
          />
        )}
      </Card>
    </>
  );
};

export default CompanyView;
